<template>
  <div class="app_con">
    <app-list :remote="remote" :props="props" :btnFlag="btnFlag" :formProps="formProps" :searchQuery="searchQuery"
      :apiName="apiName" :rules="rules" ref="appList">
      <div class="search" slot="subSlot">


      </div>
    </app-list>

  </div>
</template>

<script>
import api from "@/api/common";

export default {
  data() {

    return {
      rules: {
        price: [
          { required: true, message: "请填写面值", trigger: "blur" },

          { type: "number", message: "金额必须为数字值" },
        ],
        deductionIntegral: [
          { required: true, message: "请填写抵扣积分", trigger: "blur" },
          { type: "number", message: "积分必须为数字值" },
        ],
        deductionPrice: [
          { required: true, message: "请填写积分抵扣金额", trigger: "blur" },
          { type: "number", message: "抵扣金额必须为数字值" },
        ],
      },
      dialogShow: false,
      remote: api,
      apiName: "/payIntegralDeduction",
      handleType: "",
      searchVal: "",
      form: {},
      btnFlag: { addShow: true, delShow: false },
      searchQuery: {
        siteName: "",
        siteCode: "",
        refreshNum: 0,
        backstage: true,
      },

      props: [


        {
          label: "使用门槛（元）",
          prop: "price",
          align: "center",
          
        },

        {
          label: "积分",
          prop: "deductionIntegral",
          align: "center",
        },

        {
          label: "积分抵扣金额（元）",
          prop: "deductionPrice",
          align: "center",
        },

        {
          label: "描述",
          prop: "describeStr",
          align: "center",
        },

        
        {
          label: "状态",
          prop: "isUse",
          align: "center",
          type: 'html',
          formatter: function (row) {
            let code = "";
            if (row.isUse == 0) {
              code = "<span class='font-blue'>启用</span>";
            }
            else {
              code = "<span class='font-red'>停用</span>";
            }
            return code;
          },
        },
        {
          label: "发布时间",
          prop: "createTime",
          align: "center",
        },
        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "update",
                handler: function () { },
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "remove",
                handler: function () {

                },
              },
            ];
            return buttonList;
          },
        },


      ],
      formProps: [

        {
          label: "使用门槛",
          type: "number",
          prop: "price",
        },
        {
          label: "积分",
          type: "number",
          prop: "deductionIntegral",

        },
        {
          label: "抵扣金额",
          type: "number",
          prop: "deductionPrice",
        },
        {
          label: "描述",
          type: "input",
          prop: "describeStr",
        },
        {
          label: "状态",
          type: "select",
          selectData: [{ label: '启用', value: 0 }, { label: '停用', value: 1 }],
          prop: "isUse",
        },

      ],
    };
  },
  created() { },
  mounted() { },
  methods: { },
};
</script>

<style lang="scss" scoped>
.fullWidth {
  width: 100% !important;
}

.changeCon p {
  color: #999;
}

.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}

.search {
  display: flex;
}

.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

</style>
